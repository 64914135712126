import React from "react";
import { Box, Text } from "grommet";
import { useContext } from "react";
import appContext from "../context/appContext";
import StatusCard from "./StatusCard";
import { CircleInformation } from "grommet-icons";
import TrackedAnchor from "./TrackedAnchor";

const SummaryStatement = () => {
  const { cwdgData, isEligible } = useContext(appContext);

  return (
    <StatusCard status={isEligible} theme="dark">
      <Box gap="medium" pad={{ top: "xsmall" }}>
        <Box gap="none">
          <Text size="large" color={"light-1"}>
            <Text color="light-1" size="xlarge" weight={"bolder"}>
              {cwdgData.geoMetadata.label}
            </Text>
            {cwdgData.geoMetadata.geo_level === "tribal" ? " " : ", "}
            {isEligible === "yes" ? "is" : "may be"} eligible to apply for
            Community Wildfire Defense Grants.
          </Text>
          <Text>
            <TrackedAnchor
              size="xsmall"
              gaCategory={"Summary Statement"}
              href="https://www.fs.usda.gov/managing-land/fire/grants/cwdg"
              icon={<CircleInformation size="small" />}
              a11yTitle="Notice of Funding Opportunity"
              title="Notice of Funding Opportunity"
              label="Notice of Funding Opportunity"
              color={"light-5"}
              gap="xsmall"
            />
          </Text>
          
        </Box>

        {/* <Text weight={"bolder"} color={"light-1"}>
          Use the copy button in each section below to copy and paste
          information into your application.
        </Text> */}
      </Box>
    </StatusCard>
  );
};

export default SummaryStatement;
